import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.featureEnabled}}\n\t<AiCaseSummary\n\t\t@nodes={{this.nodes}}\n\t\t@createdAt={{this.createdAt}}\n\t\t@isLoading={{this.isLoading}}\n\t\t@regenerateSummary={{this.regenerateSummary}}\n\t />\n{{/if}}", {"contents":"{{#if this.featureEnabled}}\n\t<AiCaseSummary\n\t\t@nodes={{this.nodes}}\n\t\t@createdAt={{this.createdAt}}\n\t\t@isLoading={{this.isLoading}}\n\t\t@regenerateSummary={{this.regenerateSummary}}\n\t />\n{{/if}}","moduleName":"case-status/components/ai-case-summary-wrapper.hbs","parseOptions":{"srcName":"case-status/components/ai-case-summary-wrapper.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import ENV from 'case-status/config/environment';
import Errors from '../constants/errors';

export default class AiCaseSummaryWrapperComponent extends Component {
	@service ajax;
	@service currentUser;
	@service notifications;
	@service pubnubService;
	@service store;
	@tracked isLoading = false;
	@tracked nodes;
	@tracked createdAt;
	// Order in which the categories/nodes should render
	NODES = ['Overview', 'Blockers and client sentiment', 'Follow up items and open questions'];

	constructor() {
		super(...arguments);
		
		this.store.queryRecord('ai-case-summary', { caseId: this.args.caseId }).then((summary) => {
			if (summary) {
				this.nodes = this.formatSummary(summary.summary);
				this.createdAt = summary.createdAt;
			}
		}).catch((err) => {
			// most likely was not found
		});
		this.pubnubService.newCaseSummaryCallbacks.push((...args) => {
			this.handleIncomingSummary(...args);
		})
	}

	get featureEnabled() {
		return this.currentUser.get('user.firm.firmSettings.firmSettings.ai_case_summary_enabled') || this.args.isSandbox;
	}

	handleIncomingSummary(summary) {
		this.nodes = summary;
		this.createdAt = new Date();
	}

	formatSummary(summary) {
		const formattedSummary = new Map();
		this.NODES.map((node) => {
			formattedSummary.set(node, summary[node]);
		});
		return formattedSummary;
	}

	@action
	async regenerateSummary(event) {
		event?.stopPropagation();
		this.isLoading = true;
		try {
			const summary = await this.ajax.post(`${ENV.host}/v2/api/cases/${this.args.caseId}/summary`);
			this.nodes = this.formatSummary(summary);
			this.createdAt = new Date();
		} catch (err) {
			this.notifications.error(Errors.mapResponseErrors(err), {
				canClose: true,
				autoClear: true,
				clearDuration: 5000,
			});
		} finally {
			this.isLoading = false;
		}
	}
}
