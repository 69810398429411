import { StatsigClient as ServiceProviderClient } from '@statsig/js-client';
import {
	FEATURE_GATE_ENVIRONMENT_DEVELOPMENT,
	FEATURE_GATE_ENVIRONMENT_STAGING,
	FEATURE_GATE_ENVIRONMENT_PRODUCTION,
	UNRECOGNIZED_ERROR,
	UNINITIALIZED_ERROR,
	GENERIC_ERROR,
} from './constants';

/**
 * Class for managing feature gates and experiments with an agnostic service provider.
 */
class Client {
	/**
	 * Creates a new Client instance.
	 * @throws {Error} If an instance already exists.
	 */
	constructor() {
		if (Client.instance) {
			throw new Error('You can only create a single client instance');
		}
		Client.instance = this;
		this.user = null;
	}

	/**
	 * Initializes the ServiceProviderClient with the given key and context.
	 * @param {string} key - The key for initializing ServiceProviderClient.
	 * @param {Object} [context=null] - The context for initializing ServiceProviderClient.
	 * @returns {Promise<void>} A promise that resolves when the client is initialized.
	 */
	async init(key, context = null) {
		const TWO_AND_A_HALF_SECONDS = 2500;
		try {
			this._client = new ServiceProviderClient(key, this.user, {
				...(context || {}),
			});
			await this._client.initializeAsync({
				timeoutMs: TWO_AND_A_HALF_SECONDS,
			});
		} catch (error) {
			console.error('Failed to initialize ServiceProviderClient:', error);
		}
	}

	/**
	 * Sets the user for the Client instance.
	 * @param {Object} user - The user object.
	 * @returns {Client} The Client instance.
	 */
	withUser(user) {
		this.user = this._normalizeUser(user);
		return this;
	}

	/**
	 * Evaluates the specified feature gate.
	 *
	 * @param {string} gateName - The name of the feature gate to evaluate.
	 * @param {boolean} [fallback=false] - The fallback value to return if the feature gate evaluation fails or is not available.
	 * @returns {boolean} - The result of the feature gate evaluation.
	 */
	eval(gateName, fallback = false) {
		try {
			const evaluationResult = this._getClient().getFeatureGate(
				gateName,
				fallback,
			);
			this._checkEvaluationError(evaluationResult, gateName);
			return evaluationResult.value;
		} catch (error) {
			console.error('Failed to evaluate feature gate:', error);
			return fallback;
		}
	}

	/**
	 * Checks if the evaluation result contains an error and throws an error if it does.
	 * @private
	 * @param {Object} evaluationResult - The evaluation result object.
	 * @param {string} gateName - The name of the feature gate being evaluated.
	 * @throws {Error} If the evaluation result contains an error.
	 */
	_checkEvaluationError(evaluationResult, gateName) {
		const reason = evaluationResult.details.reason || '';
		const hasError = [
			UNRECOGNIZED_ERROR,
			UNINITIALIZED_ERROR,
			GENERIC_ERROR,
		].some((err) => reason.includes(err));

		if (hasError) {
			throw new Error(`Unable to evaluate feature gate ${gateName}: ${reason}`);
		}
	}

	/**
	 * Returns the initialized singleton Client instance.
	 * @private
	 * @returns {Client} The singleton Client instance.
	 * @throws {Error} If the Client is uninitialized.
	 */
	_getInstance() {
		if (!this._client)
			throw new Error('ServiceProviderClient is uninitialized');
		return this;
	}

	/**
	 * Returns the service provider instance.
	 * @private
	 * @returns {ServiceProviderClient} The ServiceProviderClient instance.
	 */
	_getClient() {
		return this._getInstance()._client;
	}

	/**
	 * Normalizes the user object to the format expected by ServiceProviderClient.
	 * @param {Object} user - The user object.
	 * @param {string} user.emailAddress - The user's email address.
	 * @param {string} user.firm - Ember data reference to the firm.*
	 * @param {string} user.name - The user's name.
	 * @param {string} user.id - The user's ID.
	 * @param {string} user.type - The user's type.
	 * @returns {Object} The normalized user object.
	 * @private
	 */
	_normalizeUser(user) {
		return {
			email: user.emailAddress,
			name: user.name,
			userId: user.id,
			custom: {
				type: user.type,
			},
			customIDs: {
				firmId: user.get('firm.id'),
			},
		};
	}

	/**
	 * Normalizes the environment string to a valid environment tier.
	 * @param {string} environment - The environment string.
	 * @returns {string} The normalized environment tier.
	 */
	normalizeEnvironment(environment) {
		return Client.ENVIRONMENT_MAPPING[environment] || 'unknown';
	}

	/**
	 * Mapping of environment strings to their corresponding environment tiers.
	 */
	static ENVIRONMENT_MAPPING = {
		local: FEATURE_GATE_ENVIRONMENT_DEVELOPMENT,
		development: FEATURE_GATE_ENVIRONMENT_DEVELOPMENT,
		test: FEATURE_GATE_ENVIRONMENT_DEVELOPMENT,
		'prod-dev': FEATURE_GATE_ENVIRONMENT_DEVELOPMENT,
		qa: FEATURE_GATE_ENVIRONMENT_STAGING,
		staging: FEATURE_GATE_ENVIRONMENT_STAGING,
		production: FEATURE_GATE_ENVIRONMENT_PRODUCTION,
	};
}

export default new Client();
