import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import EmberObject from '@ember/object';
import automationOptions, {
	FrequencyTypes,
} from '../constants/automation-options';
export default class ModalCustomAutomationFrequencyComponent extends Component {
	@tracked frequencyAmount = this.args.frequencyAmount;
	@tracked frequencyType = this.args.frequencyType;
	@tracked weekDayOptions = automationOptions.weekDayOptions.map((day) =>
		EmberObject.create({
			...day,
			checked:
				this.args.weekDayOptions?.some(
					(opt) => opt.label === day.label && opt.checked,
				) ?? false,
		}),
	);
	@tracked monthDayOptions = automationOptions.monthDayOptions;
	@tracked hourOptions = automationOptions.hourOptions;
	@tracked triggerTypeOptions = automationOptions.triggerTypeOptions;
	@tracked primaryMonthDayOption = this.args.primaryMonthDayOption ?? null;
	@tracked selectedMonth = this.args.selectedMonth ?? null;
	@tracked selectedDay = this.args.selectedDay ?? null;
	@tracked validationError = null;

	get frequencyTypes() {
		return Object.values(FrequencyTypes);
	}

	get monthOptions() {
		return automationOptions.monthOptions;
	}

	@action
	selectRecurrenceUnit(unit) {
		this.frequencyType = unit;
	}

	@action
	toggleWeekDay(weekDay) {
		weekDay.checked = !weekDay.checked;
	}

	@action
	selectPrimaryMonthDayOption(option) {
		this.primaryMonthDayOption = option;
	}

	@action
	selectMonth(option) {
		this.selectedMonth = option;
	}

	@action
	selectDay(option) {
		this.selectedDay = option;
	}

	@action
	saveCustomRecurrence() {
		// Clear previous validation error
		this.validationError = null;

		// Validate the input fields
		if (!this.frequencyAmount || !this.frequencyType) {
			this.validationError =
				'Please provide both frequency amount and frequency type.';
			return;
		}

		// Validate that frequencyAmount is a whole number
		if (!Number.isInteger(Number(this.frequencyAmount))) {
			this.validationError = 'Frequency amount must be a whole number.';
			return;
		}

		const data = {
			frequencyAmount: Number(this.frequencyAmount),
			frequencyType: this.frequencyType,
			weekDayOptions: this.weekDayOptions,
			primaryMonthDayOption: this.primaryMonthDayOption,
			selectedMonth: this.selectedMonth,
			selectedDay: this.selectedDay,
		};

		this.args.onSave(data); // Pass data back to the parent component
		this.args.close(); // Close the modal after saving
	}

	@action
	close() {
		this.args.close(); // Close the modal without saving
	}
}
