export const CLIENT_PORTAL_UPGRADE_URL = 'https://www.portalforlegal.com/upgrade';
export const UPGRADE_URL = 'http://www.casestatus.com/upgrade ';


/**
 * Returns the upgrade URL based on the client portal texting status on firmSettings.
 * 
 * @param {Object} firmSettings - The firmSettings object.
 * @param {boolean} firmSettings.client_portal_texting - Indicates if client portal texting is enabled.
 * @returns {string} - The URL for upgrading.
 */
export const getUpgradeLink = ({
    client_portal_texting
}) => {
    return client_portal_texting ? CLIENT_PORTAL_UPGRADE_URL : UPGRADE_URL;
}
